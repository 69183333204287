<template>
  <div class="modal fade " id="serviceSourceEditModal" tabindex="-1" role="dialog"
       aria-labelledby="serviceSourceEditModal" aria-hidden="true">
    <div class="modal-dialog  modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h1 class="modal-title white">Update Source</h1>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <div class="controls">
                    <label>Source</label>
                    <VueMultiselect :allowEmpty="false" v-model="selectedSource" class="multiselect-blue"
                                    :options="sourceOption" :close-on-select="true" placeholder="Select source"
                                    label="name" track-by="value" :show-labels="false"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer border-0 pt-0">
          <button type="button" class="btn btn-primary" @click="updateSourceHandler">
            <span class="">Save Changes</span>
          </button>
          <!-- data-dismiss="modal" -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {

  mapActions,
  mapGetters
} from 'vuex';
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import VueMultiselect from 'vue-multiselect';

export default {
  mixins: [ShowToastMessage, Loader],
  name: 'ServiceSourceEditModal',
  components: {
    VueMultiselect
  },
  data() {
    return {
      selectedSource: {
        value: 0,
        name: "Default",
      },
    }
  },
  computed: {
    ...mapGetters({
      service: 'appService/service',
    }),
    sourceOption() {
      let sources = this.$store.getters['appReferrals/referrals'].map(item => {
        return {
          value: item.id,
          name: item.code,
        };
      })
      return [...sources];
    },
  },
  watch: {
    service(currentService) {
      this.selectedSource = this.sourceOption.find((source) => source.name.trim().toLowerCase() === currentService.source.trim().toLowerCase());
    },

  },
  methods: {
    ...mapActions({
      putService: 'appService/putService',

    }),
    async updateSourceHandler() {
      let data = {
        source: this.selectedSource.value,
      }
      const dataObj = {
        id: this.$route.params.id,
        data: data
      }
      this.loader(true);
      const response = await this.putService(dataObj);
      this.loader(false);
      if (response.status === 200 || response.status === 201) {
        this.errors = {};
        this.showToastMessage(response.message);
        document.querySelector('[data-target="#serviceSourceEditModal"]').click();
        this.$emit('getSingleService', this.$route.params.id);

      } else {
        this.errors = response.errors;
      }
      if (response.message) {
        this.showToastMessage(response);
      }
    }
  },
}
</script>

<style scoped>

</style>
