const ToggleModal = {
    methods: {
        async toggleModal(modalNameById) {
            if (typeof modalNameById !== 'string') {
                throw new TypeError("Parameter 'modalNameById' must be a string");
            }
            document.querySelector(`[data-target="#${modalNameById}"]`).click(); // modal toggle
        },
    }
};

export default ToggleModal;