<script>
import showToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import confirmDialogue from "@/components/backEnd/modal/ConfirmDialogue.vue";

export default {
  name: "MetaInformationModal",
  mixins: [showToastMessage],
  components: {confirmDialogue},
  emits: ['onMetaInformation'],
  props: {
    modalName: {
      type: String,
      default: "metaInformationModal"
    },
    modalUsagesFor: {
      type: Number,
      default: 1, // 1 for store only, 2 for update only.
      required: true,
    },
    initialMetaRows: {
      type: Array,
      default: () => [],
    },
    excludeMetaNames: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      newMetaName: "",
      metaNames: [], // List of available meta names
      metaRows: [], // List of meta attribute rows with select and input
    };
  },
  computed: {
    // Available meta names for selection in Section Two
    availableMetaNames() {
      return this.metaNames;
    },
  },
  watch: {
    // Watch for changes in the prop to update local meta rows if it’s modified outside
    initialMetaRows: {
      immediate: true,
      handler(newVal) {
        this.metaRows = newVal?.map(row => ({
          selectedMetaName: row.name,
          metaContent: row.content,
        }));
        this.metaNames = newVal?.map(row => row.name);
      },
    },
  },
  methods: {
    addMetaName() {
      if (this.newMetaName && !this.metaNames.includes(this.newMetaName) && !this.excludeMetaNames.includes(this.newMetaName)) {
        this.metaNames.push(this.newMetaName);
        this.newMetaName = "";
      } else {
        this.showToastMessage({
          message: "Meta attribute name is either empty or already exists or in exclude names",
          type: 'warning'
        });
      }
    },
    removeMetaName(index) {
      const nameToRemove = this.metaNames[index];
      // Check if the name to be removed is selected in any row with non-empty content
      const isUsedInMetaRows = this.metaRows.some(row =>
          row.selectedMetaName === nameToRemove && row.metaContent.trim() !== ""
      );

      if (isUsedInMetaRows) {
        this.showToastMessage({
          message: "Cannot delete meta attribute because it is in use with non-empty content.",
          type: "warning"
        });
      } else {
        this.metaNames.splice(index, 1);
      }
    },
    addMetaRow() {
      if (this.metaRows.length === this.metaNames.length) return;
      // Add a new row object with default empty values
      this.metaRows.push({selectedMetaName: "", metaContent: ""});
    },
    removeMetaRow(index) {
      let metaRowLength = this.metaRows.length
      if (metaRowLength === 1) {
        this.$refs.confirmDialogue.show({
          title: 'Confirm',
          message: 'Do you want to delete this row?',
          okButton: 'Yes',
          cancelButton: 'No',
        }).then(async (confirmResponse) => {
          if (confirmResponse === true) {
            this.metaRows.splice(index, 1);
          }
        });
      } else {
        this.metaRows.splice(index, 1);
      }
    },
    isMetaNameSelected(name) {
      // Check if the meta name is selected in any row
      return this.metaRows.some(row => row.selectedMetaName === name);
    },
    checkEmptyFields() {
      return this.metaRows.some(row => !row.selectedMetaName || !row.metaContent);
    },
    allSet() {
      if (this.checkEmptyFields()) {
        this.showToastMessage({
          message: "Please fill out all meta attributes and content.",
          type: "warning",
        });
        return;
      }
      const rows = this.metaRows?.map(row => ({
        name: row.selectedMetaName,
        content: row.metaContent,
      }))
      this.$emit('onMetaInformation', rows);
    }
  },
}
</script>

<template>
  <div class="modal fade " :id="modalName" tabindex="-1" role="dialog" :aria-labelledby="modalName" aria-hidden="true">
    <div class="modal-dialog  modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h1 class="modal-title white">
            {{ modalUsagesFor === 1 ? "Add Meta Information" : "Update Meta Information" }}
          </h1>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <div class="row">
              <section class="container py-1">
                <!-- section one -->
                <h6>Add meta name attribute</h6>
                <div id="section_one"
                     style="position: relative; padding: 10px 20px; box-shadow: 0px 0px 4px 0px #00000040; border-radius: 10px;">
                  <div class="d-flex flex-column flex-lg-row">
                    <div class="col-12 col-lg-10 px-0">
                      <input style="outline: none" class="bg-transparent d-flex w-100 mb-2 border-0"
                             placeholder="Add new meta name..."
                             v-model="newMetaName" @keyup.enter="addMetaName"/>
                      <div class="d-flex flex-wrap align-items-center">
                        <div class="d-flex align-items-center mr-1 mb-1" v-for="(name, index) in metaNames"
                             :key="index">
                          <p style="background-color: #222222E5; color: #fff; padding: 5px 15px; margin: 0; border-radius: 5px 0 0 5px;">
                            {{ name }}</p>
                          <button
                              @click="removeMetaName(index)"
                              style="background-color: #F45D2E; padding: 5.7px 8px; color: #fff; margin: 0; border: none; border-radius: 0 5px 5px 0;">
                            x
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-lg-2 bg-rgba-danger mb-1 mb-md-0 rounded-lg" style="padding: 5px 0 5px 5px"
                         v-if="excludeMetaNames.length > 0">

                      <span v-for="(excludeName, index) in excludeMetaNames" :key="index"
                            class="badge badge-pill badge-secondary mb-0 mb-md-1"> {{ excludeName }}
                      </span>
                    </div>
                  </div>
                </div>

                <!-- section two -->
                <h6 class="mt-2">Add meta row with name & content</h6>
                <div id="section_two" class="px-1 py-2 px-md-2 py-md-2"
                     style="box-shadow: 0px 0px 4px 0px #00000040; border-radius: 10px;">
                  <div class="mb-1 mb-lg-2">
                    <div class="d-flex flex-column flex-lg-row align-items-start" v-for="(row, index) in metaRows"
                         :key="index">
                      <div class="col-12 col-lg-3">
                        <select class="selects mb-1 mb-lg-2 mr-lg-1 w-100" v-model="row.selectedMetaName"
                                style="border-radius: 5px;  width: 100%; padding: 10px 10px;">
                          <option disabled value="">Select name</option>
                          <option
                              v-for="name in availableMetaNames"
                              :key="name"
                              :value="name"
                              :disabled="isMetaNameSelected(name) && row.selectedMetaName !== name">
                            {{ name }}
                          </option>
                        </select>
                      </div>

                      <div class="col-12 col-lg-7">
                        <textarea name="details" placeholder="content" id="" class="mb-1 mb-lg-2 mr-lg-1 w-100"
                                  v-model="row.metaContent"
                                  style="width: 100%; height: 50px; border-radius: 5px; padding: 10px"></textarea>
                      </div>
                      <div class="col-12 col-lg-2">
                        <button class="px-1 m-0 w-100 w-lg-25" @click="removeMetaRow(index)"
                                style="width: 120px; border: none; background-color: #F45D2E; color: #fff; border-radius: 5px; padding: 7px 0px">
                          Remove
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex flex-row align-items-center mt-1 mt-lg-0">
                    <button @click="allSet" class="btn btn-dark glow w-50 mr-2">
                      <i class="bx bx-check"></i>
                      <span class="align-middle ml-25"></span>All Set
                    </button>
                    <button @click="addMetaRow" class="btn btn-success glow w-50">
                      <i class="bx bx-plus"></i>
                      Add new row
                    </button>
                  </div>

                </div>
              </section>
            </div>
          </div>
        </div>
        <!-- modal footer-->
      </div>
    </div>
    <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
  </div>

</template>

<style scoped>

</style>